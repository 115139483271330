<template>
    <fieldset class="underlay challenge-history-container">
        <legend class="game-history-title">{{ $t('arenaRoute.history') }}</legend>
        <div class="game-history-container">
            <div class="game-history-stats">
                <div class="games-stats-card">
                    <span>{{ $t('arenaRoute.historyPoints') }}</span>
                    <span class="number">{{ completedChallengePoints }}</span>
                </div>
                <div class="games-stats-card right">
                    <span>{{ $t('arenaRoute.historyGamesCount') }}</span>
                    <span class="number">{{ completedChallengeSessions.length }}</span>
                </div>
            </div>
            <div class="history-cards-container">
                <challenge-session-history-card
                    v-for="(session, index) in completedChallengeSessions.slice(0, showingCards)"
                    :key="`session-${index}`"
                    :session="session"
                />
            </div>
            <div v-show="completedChallengeSessions.length > 0" class="history-view-buttons">
                <font-awesome-icon
                    class="btn-history"
                    :class="{ disabled: showingCards === 2 }"
                    :icon="['fas', 'chevron-up']"
                    size="lg"
                    :disabled="showingCards === 2"
                    @click="decreaseViewableSessions"
                />
                <font-awesome-icon
                    class="btn-history"
                    :class="{ disabled: showingCards >= completedChallengeSessions.length }"
                    :icon="['fas', 'chevron-down']"
                    size="lg"
                    :disabled="showingCards >= completedChallengeSessions.length"
                    @click="increaseViewableSessions"
                />
            </div>
        </div>
    </fieldset>
</template>

<script>
    import ChallengeSessionHistoryCard from '@/components/arena/ChallengeSessionHistoryCard'

    export default {
        components: { ChallengeSessionHistoryCard },
        props: {
            completedChallengeSessions: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                showingCards: 2
            }
        },
        computed: {
            completedChallengePoints() {
                return this.completedChallengeSessions.reduce((total, session) => {
                    return total + session.userPoints
                }, 0)
            }
        },
        methods: {
            increaseViewableSessions() {
                this.showingCards = this.showingCards < this.completedChallengeSessions.length
                    ? this.showingCards + 2
                    : this.showingCards
            },
            decreaseViewableSessions() {
                if (this.showingCards > 2) {
                    this.showingCards -= 2
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.game-history-title {
    color: var(--arena-highlight-color);
    background-color: #fff;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    width: fit-content;
    padding: 0.5rem 3rem;
    border-radius: 1.85rem;
    margin: 0 auto;
}

.challenge-history-container {
    display: flex;
    flex-direction: column;
}

.underlay.challenge-history-container {
    background: rgb(255 255 255 / 7.5%);
    margin-top: 1.5rem;
    padding: 1rem;
}

.game-history-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.game-history-stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-width: max-content;
    margin: 0 auto;
}

.games-stats-card {
    color: var(--arena-main-text-color);
    border-radius: 0.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-size: 1rem;
}

.games-stats-card .number {
    color: var(--arena-highlight-color);
    font-size: 1.5rem;
}

.history-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.history-view-buttons {
    padding: 1rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    .btn-history {
        color: #fff;
        cursor: pointer;

        &.disabled {
            color: #7b7f87;
            cursor: default;
        }
    }
}

@media screen and (min-width: 992px) {
    .history-cards-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .games-stats-card {
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        font-size: 1.25rem;
    }
}
</style>
