import moment from 'moment'

export default {
    getDurationFromTime(from) {
        const now = moment()
        const fromMoment = moment(from)

        return moment.duration(now.diff(fromMoment)).asHours()
    },
    getDurationToTime(to) {
        const now = moment()
        const toMoment = moment(to)

        return moment.duration(toMoment.diff(now)).asHours()
    }
}
