import { render, staticRenderFns } from "./ChallengeSessionHistoryCard.vue?vue&type=template&id=63b09242&scoped=true&"
import script from "./ChallengeSessionHistoryCard.vue?vue&type=script&lang=js&"
export * from "./ChallengeSessionHistoryCard.vue?vue&type=script&lang=js&"
import style0 from "./ChallengeSessionHistoryCard.vue?vue&type=style&index=0&id=63b09242&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b09242",
  null
  
)

export default component.exports