<template>
    <div class="challenge-session-history-card">
        <div class="session-info-container">
            <div v-dompurify-html="$t('arenaRoute.historySessionCardGameInfo', { gameTitle: session.locale.alias, opponent: opponentChallengerId })" class="info" />
            <div class="session-date">
                <span>{{ sessionEndDate }}</span>
                <span>|</span>
                <span>{{ sessionEndTime || '00:00:00' }}</span>
                <div class="more-btn" @click="toggleScore">
                    {{ $t('arenaRoute.moreBtn') }}
                    <font-awesome-icon
                        :icon="['fas','chevron-down']"
                        class="chevron-icon"
                        :class="{ 'rotate-180': isScoreTooltipOpen }"
                    />
                    <transition
                        name="fade"
                        mode="out-in"
                    >
                        <div
                            v-if="isScoreTooltipOpen"
                            class="more-info-text"
                        >
                            <span />
                            <span class="username creator"> {{ session.creatorChallengerId }}</span>
                            <span class="username joiner"> {{ session.joinerChallengerId }}</span>
                            <span class="points-label">Points</span>
                            <span class="points creator-points">
                                {{ session.challengeCreatorPoints }}
                            </span>
                            <span class="points joiner-points">
                                {{ session.challengeJoinerPoints }}
                            </span>
                            <span class="duration-label">Duration</span>
                            <span class="creator-duration">
                                {{ getPaddingTime(session.challengeCreatorGameDuration) }}
                            </span>
                            <span class="joiner-duration">
                                {{ getPaddingTime(session.challengeJoinerGameDuration) }}
                            </span>

                        </div>
                    </transition>

                </div>
            </div>
        </div>
        <div class="score">
            <div class="points">
                +{{ session.userPoints }}
            </div>
            <div class="icon-container" :class="{ 'thumbs-up': session.userPoints > 0 }">
                <font-awesome-icon :icon="['fas', session.userPoints > 0 ? 'thumbs-up' : 'thumbs-down']" class="thumbs-icon" />
            </div>
        </div>
    </div>
</template>

<script>
    import dateConversion from '@/utils/date-conversion'

    export default {
        props: {
            session: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isScoreTooltipOpen: false
            }
        },
        computed: {
            opponentChallengerId() {
                return this.session.creator
                    ? this.session.joinerChallengerId
                    : this.session.creatorChallengerId
            },
            sessionEndDate() {
                return dateConversion
                    .utcToLocalTime(
                        this.session.challengeJoinerEndDate || this.session.challengeCreatorEndDate,
                        'YYYY-MM-DD HH:mm:ss'
                    ).split(' ')[0]
            },
            sessionEndTime() {
                return dateConversion
                    .utcToLocalTime(
                        this.session.challengeJoinerEndDate || this.session.challengeCreatorEndDate,
                        'YYYY-MM-DD HH:mm:ss'
                    ).split(' ').pop()
            }
        },
        mounted() {
            document.addEventListener('click', this.onClickOutside)
        },
        beforeDestroy() {
            document.removeEventListener('click', this.onClickOutside)
        },
        methods: {
            onClickOutside(event) {
                if (this.isScoreTooltipOpen && !this.$el.contains(event.target)) {
                    this.isScoreTooltipOpen = false
                }
            },
            toggleScore() {
                this.isScoreTooltipOpen = !this.isScoreTooltipOpen
            },
            getPaddingTime(time) {
                return dateConversion.getPaddedDigitsString(time, false)
            }
        }
    }
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.25s;
    transition-delay: 0s;
}

.challenge-session-history-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: #e8e8e8;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

.session-info-container {
    font-size: 0.8rem;
    text-align: start;
}

.session-date {
    display: flex;
    gap: 1rem;
}

.score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.more-btn {
    cursor: pointer;
    position: relative;
}

.chevron-icon {
    transition: all 0.25s ease-in-out;
}

.rotate-180 {
    transform: rotate(-180deg);
}

.points {
    color: var(--arena-highlight-color);
    font-weight: 700;
}

.more-info-text {
    position: absolute;
    top: 100%;
    left: -120px;
    z-index: 2;
    display: grid;
    grid-template:
        ". creator joiner"
        "points-label creator-points joiner-points"
        "duration-label creator-duration joiner-duration";
    column-gap: 1rem;
    row-gap: 0.25rem;
    background-color: #e8e8e8;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 20px rgb(0 0 0 / 15%);
    min-width: 120px;
    text-align: center;
}

.points-label {
    grid-area: points-label;
    font-weight: 700;
}

.duration-label {
    grid-area: duration-label;
    font-weight: 700;
}

.creator-points {
    grid-area: creator-points;
    color: #000;
    text-align: center;
    font-weight: 400;
}

.joiner-points {
    grid-area: joiner-points;
    color: #000;
    text-align: center;
    font-weight: 400;
}

.creator-duration {
    grid-area: creator-duration;
    color: #000;
    text-align: center;
    font-weight: 400;
}

.joiner-duration {
    grid-area: joiner-duration;
    color: #000;
    text-align: center;
    font-weight: 400;
}

.more-info-text .username {
    font-weight: 700;
}

.icon-container {
    background-color: #7b7f87;
    padding: 0.5rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbs-up {
    background-color: #00a5e3;
}

.thumbs-icon {
    color: #fff;
}

@media screen and (min-width: 992px) {
    .score {
        flex-direction: row;
        gap: 1rem;
    }

    .score .points {
        font-size: 1.5rem;
    }
}
</style>
