var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"loader-container",style:({
        color: _vm.loaderColor
    }),attrs:{"fluid":""}},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"spinner3"},[_c('div',{staticClass:"container-b"},[_c('div',{staticClass:"hex0",style:({
                        borderColor: _vm.loaderColor
                    })}),_c('div',{staticClass:"hex120",style:({
                        borderColor: _vm.loaderColor
                    })}),_c('div',{staticClass:"hex240",style:({
                        borderColor: _vm.loaderColor
                    })}),_c('div',{staticClass:"spinner3"},[_c('div',{staticClass:"container-b"},[_c('div',{staticClass:"hex0",style:({
                                borderColor: _vm.loaderColor
                            })}),_c('div',{staticClass:"hex120",style:({
                                borderColor: _vm.loaderColor
                            })}),_c('div',{staticClass:"hex240",style:({
                                borderColor: _vm.loaderColor
                            })}),_c('div',{staticClass:"spinner3"},[_c('div',{staticClass:"container-b"},[_c('div',{staticClass:"hex0",style:({
                                        borderColor: _vm.loaderColor
                                    })}),_c('div',{staticClass:"hex120",style:({
                                        borderColor: _vm.loaderColor
                                    })}),_c('div',{staticClass:"hex240",style:({
                                        borderColor: _vm.loaderColor
                                    })})])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }