<template>
    <b-container
        fluid
        class="loader-container"
        :style="{
            color: loaderColor
        }"
    >
        <div class="loader">
            <div class="spinner3">
                <div
                    class="container-b"
                >
                    <div
                        class="hex0"
                        :style="{
                            borderColor: loaderColor
                        }"
                    />
                    <div
                        class="hex120"
                        :style="{
                            borderColor: loaderColor
                        }"
                    />
                    <div
                        class="hex240"
                        :style="{
                            borderColor: loaderColor
                        }"
                    />
                    <div class="spinner3">
                        <div class="container-b">
                            <div
                                class="hex0"
                                :style="{
                                    borderColor: loaderColor
                                }"
                            />
                            <div
                                class="hex120"
                                :style="{
                                    borderColor: loaderColor
                                }"
                            />
                            <div
                                class="hex240"
                                :style="{
                                    borderColor: loaderColor
                                }"
                            />
                            <div class="spinner3">
                                <div class="container-b">
                                    <div
                                        class="hex0"
                                        :style="{
                                            borderColor: loaderColor
                                        }"
                                    />
                                    <div
                                        class="hex120"
                                        :style="{
                                            borderColor: loaderColor
                                        }"
                                    />
                                    <div
                                        class="hex240"
                                        :style="{
                                            borderColor: loaderColor
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>
<script>
    export default {
        name: 'Loader',
        props: {
            loaderColor: {
                type: String,
                default: 'var(--logo-highlight-color)'
            }
        }
    }
</script>

<style lang="scss" scoped>
.loader-container {
    position: relative;
    color: var(--logo-highlight-color);
}

.loader {
    width: 200px;
    height: 200px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50px;
}

.loader .container-b {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.loader .spinner3 {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: rotation0 6s infinite linear, resize3 6s infinite linear;
}

.loader .hex0,
.hex120,
.hex240 {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 57.74%;
    left: 0;
    top: 21.13%;
    border-left: 2px solid;
    border-color: var(--logo-highlight-color);
    border-right: 2px solid;
}
.loader .hex0 { animation: rotation0 4s infinite linear; }

.loader .hex120 {
    transform: rotate(120deg);
    animation: rotation120 4s infinite linear;
}

.loader .hex240 {
    transform: rotate(240deg);
    animation: rotation240 4s infinite linear;
}

@keyframes rotation0 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes rotation0 {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes rotation120 {
    0% { transform: rotate(120deg); }
    100% { transform: rotate(480deg); }
}

@keyframes rotation120 {
    0% { transform: rotate(120deg); }
    100% { transform: rotate(480deg); }
}

@keyframes rotation240 {
    0% { transform: rotate(240deg); }
    100% { transform: rotate(600deg); }
}

@keyframes rotation240 {
    0% { transform: rotate(240deg); }
    100% { transform: rotate(600deg); }
}

@keyframes resize3 {
    0%,
    16.66%,
    33.33%,
    50%,
    66.66%,
    83.33%,
    100% {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    2.77%,
    19.44%,
    36.11%,
    52.77%,
    69.44%,
    86.11% {
        width: 93.5%;
        height: 93.5%;
        top: 3.25%;
        left: 3.25%;
    }

    5.55%,
    22.22%,
    38.88%,
    55.55%,
    72.22%,
    88.88% {
        width: 84.4%;
        height: 84.4%;
        top: 7.8%;
        left: 7.8%;
    }

    8.33%,
    25%,
    41.66%,
    58.33%,
    75%,
    91.66% {
        width: 73.2%;
        height: 73.2%;
        top: 13.4%;
        left: 13.4%;
    }

    11.11%,
    27.77%,
    44.44%,
    61.11%,
    77.77%,
    94.44% {
        width: 84.4%;
        height: 84.4%;
        top: 7.8%;
        left: 7.8%;
    }

    13.88%,
    30.55%,
    47.22%,
    63.88%,
    80.55%,
    97.22% {
        width: 93.5%;
        height: 93.5%;
        top: 3.25%;
        left: 3.25%;
    }
}

@keyframes resize3 {
    0%,
    16.66%,
    33.33%,
    50%,
    66.66%,
    83.33%,
    100% {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    2.77%,
    19.44%,
    36.11%,
    52.77%,
    69.44%,
    86.11% {
        width: 93.5%;
        height: 93.5%;
        top: 3.25%;
        left: 3.25%;
    }

    5.55%,
    22.22%,
    38.88%,
    55.55%,
    72.22%,
    88.88% {
        width: 84.4%;
        height: 84.4%;
        top: 7.8%;
        left: 7.8%;
    }

    8.33%,
    25%,
    41.66%,
    58.33%,
    75%,
    91.66% {
        width: 73.2%;
        height: 73.2%;
        top: 13.4%;
        left: 13.4%;
    }

    11.11%,
    27.77%,
    44.44%,
    61.11%,
    77.77%,
    94.44% {
        width: 84.4%;
        height: 84.4%;
        top: 7.8%;
        left: 7.8%;
    }

    13.88%,
    30.55%,
    47.22%,
    63.88%,
    80.55%,
    97.22% {
        width: 93.5%;
        height: 93.5%;
        top: 3.25%;
        left: 3.25%;
    }
}
</style>
