<template>
    <div class="underlay invitations-container">
        <span class="game-invitations-title">{{ $t('arenaRoute.invitations') }}</span>
        <div class="game-invitations-container">
            <challenge-invitation-card
                v-for="(session, index) in pendingChallengeSessions.slice(0, showingInvitations)"
                :key="`session-${index}`"
                :session="session"
            />
        </div>
        <div v-show="pendingChallengeSessions.length > 2" class="invitation-view-buttons">
            <font-awesome-icon
                class="btn-invitation"
                :class="{ disabled: showingInvitations === 2 }"
                :icon="['fas', 'chevron-up']"
                size="lg"
                :disabled="showingInvitations === 2"
                @click="decreaseViewableSessions"
            />
            <font-awesome-icon
                class="btn-invitation"
                :class="{ disabled: showingInvitations >= pendingChallengeSessions.length }"
                :icon="['fas', 'chevron-down']"
                size="lg"
                :disabled="showingInvitations >= pendingChallengeSessions.length"
                @click="increaseViewableSessions"
            />
        </div>
    </div>
</template>

<script>
    import ChallengeInvitationCard from '@/components/arena/ChallengeInvitationCard.vue'

    export default {
        components: { ChallengeInvitationCard },
        props: {
            pendingChallengeSessions: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                showingInvitations: 2
            }
        },
        methods: {
            increaseViewableSessions() {
                this.showingInvitations = this.showingInvitations < this.pendingChallengeSessions.length
                    ? this.showingInvitations + 2
                    : this.showingInvitations
            },
            decreaseViewableSessions() {
                if (this.showingInvitations > 2) {
                    this.showingInvitations -= 2
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.invitations-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.underlay {
        background: rgb(255 255 255 / 7.5%);
        margin-top: 2rem;
    }

    .game-invitations-title {
        color: #fff;
        font-weight: 700;
        font-size: 1.25rem;
    }

    .game-invitations-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    .invitation-view-buttons {
        padding: 1rem;
        display: flex;
        gap: 2rem;

        .btn-invitation {
            color: #fff;
            cursor: pointer;

            &.disabled {
                color: #7b7f87;
                cursor: default;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .invitations-container {
        .game-invitations-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .game-invitations-title {
            align-self: start;
        }
    }
}
</style>
