<template>
    <div
        class="challenge-bg"
        :style="{ 'background-image': `url(${challenge.type.imageUrl})` }"
    >
        <div
            class="challenge-content"
            :class="{'completed-overlay': challenge.completed}"
        >
            <span
                class="challenge-title"
                :class="{'completed-overlay': challenge.completed}"
            >
                <span class="alias-text">{{ alias }}</span>
                <span class="points-text">+ {{ challenge.challengePoints }} {{ $t('taskTile.points') }}</span>
            </span>
            <div class="challenge-button-wrapper">
                <div class="input-group">
                    <input
                        v-model="joinerChallengerId"
                        type="text"
                        class="form-control input-height"
                        :class="{ disabled: !hasRemainingChallengeSessions }"
                        :disabled="!hasRemainingChallengeSessions"
                        :placeholder="$t('arenaRoute.challengeSearchPlaceholder')"
                        trim
                    >
                </div>
                <span class="bottom-title">
                    <span
                        class="btn start-button"
                        :class="{
                            disabled: !hasRemainingChallengeSessions,
                            random: !joinerChallengerId
                        }"
                        @click="createChallengeSession(!joinerChallengerId)"
                    >
                        {{ challengeButtonLabel }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import playerService from '@/services/player-service'
    import challengeSessionService from '@/services/challenge-session-service'

    export default {
        props: {
            challenge: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                challengeId: this.challenge.id,
                joinerChallengerId: this.$route?.query?.joinerId || ''
            }
        },
        computed: {
            alias() {
                if (!this.challenge?.multilingualChallenge?.config) {
                    return 'NO_TRANSLATION_ALIAS'
                }
                return this.challenge.multilingualChallenge.config.alias
            },
            hasRemainingChallengeSessions() {
                return this.$store.getters.getRemainingChallengeSessions > 0
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            challengeButtonLabel() {
                return !this.joinerChallengerId
                    ? this.$t('arenaRoute.challengeRandomButton')
                    : this.$t('arenaRoute.challengePlayerByIdButton')
            }
        },
        methods: {
            async createChallengeSession(isRandom) {
                if (!this.hasRemainingChallengeSessions) {
                    return
                }
                try {
                    if (!this.player.challengesParticipation) {
                        await this.toggleMetaData()
                    }
                    await challengeSessionService
                        .checkValidity({
                            challengeId: this.challengeId,
                            joinerChallengerId: isRandom ? null : this.joinerChallengerId
                        })
                    const challengeData = isRandom ? { challengeId: this.challengeId } : { challengeId: this.challengeId,
                                                                                           joinerChallengerId: this.joinerChallengerId }

                    this.$store.dispatch('setCreateChallengeSessionData', challengeData)
                    this.$router.push(`/challenges/${this.challengeId}`)
                    return
                } catch (e) {
                    this.displayToast(e)
                }
            },
            async toggleMetaData() {
                await playerService.toggleMetaDataOption('CHALLENGE_PARTICIPATION')
                playerService.toggleMetaDataOption('CHALLENGE_NOTIFICATION_SUBSCRIPTION')
            }
        }
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}

.challenge-bg {
    width: 100%;
    height: 325px;
    max-width: 225px;
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
}

.challenge-content {
    --background-overlay: rgb(0 0 0 / 2%);
    --completed-background-overlay: rgb(0 0 0 / 60%);

    display: flex;
    flex-direction: column;
    background: var(--background-overlay);
    width: 100%;
}

.challenge-content.completed-overlay {
    background: var(--completed-background-overlay);
}

.challenge-title {
    font-weight: 700;
    font-size: 22px;
    color: var(--white);
    width: 100%;
    min-height: 113px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 1rem 0;
    margin: 0 auto;
    flex-grow: 1;
}

.challenge-title.completed-overlay {
    background: transparent;
}

.status-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
}

.info {
    background-color: var(--white);
    background-size: cover;
    color: var(--black);
}

svg.check {
    font-size: 2rem;
}

svg.check path {
    color: var(--white);
}

svg.lock {
    font-weight: 700;
    font-size: 20px;
}

svg.lock path {
    color: var(--error);
}

.challenge-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.75rem;
}

.bottom-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.play-again-container {
    position: relative;
}

.play-again {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.play-again-text {
    font-size: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

.locked-img,
.completed-img {
    transform: translateY(2rem);
}

.completed-text {
    font-weight: 700;
    font-size: 1.25rem;
    color: var(--white);
    width: 100%;
}

.alias-text {
    display: flex;
    flex-wrap: wrap;
}

.points-text {
    font-weight: 700;
    font-size: 14px;
    color: var(--white);
    margin-bottom: 0;
}

.locked-text {
    font-weight: 700;
    color: var(--black);
    font-size: 16px;
    background: var(--white);
    padding: 10px 20px;
}

.input-height {
    height: 48.6px;
}

.start-button {
    margin-top: 1rem;
    height: 48.6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 3.5px 3.5px 0 rgb(0 0 0 / 25%);
    border-radius: 4px;
    background: var(--arena-challenge-btn-bg-color);
    color: var(--arena-challenge-btn-text-color);
    transition: all 0.2s ease-in-out;
    outline: none;
    display: grid;
    place-items: center;
}

.start-button.random {
    background: var(--arena-random-btn-bg-color);
    color: var(--arena-random-btn-text-color);
}

</style>
