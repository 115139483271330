<template>
    <div id="arena" class="arena-container">
        <div class="arena-title">
            <div class="title">
                <img :src="theming.arenaHeaderIcon" alt="versus icon" class="arena-header-icon">
                <span>
                    {{ $t('arenaRoute.titleText') }}
                </span>
            </div>
        </div>
        <read-more
            :text="routeSubtitle"
            class="arena-subtitle"
        />
        <div
            v-if="currentPlayer.challengerId"
            ref="challengerId"
            class="arena-id"
        >
            <div @click="copyToClipboard">
                {{ $t('arenaRoute.challengerId') }}: {{ currentPlayer.challengerId }}
            </div>
            <div @click="goToArenaSettings">
                <edit-icon-svg
                    class="edit-arena-id"
                    svg-background="transparent"
                    svg-color="#404e9e"
                />
            </div>
        </div>
        <div v-if="!isLoading">
            <div class="underlay game-create-container">
                <div class="game-create-header">
                    <div
                        :class="{ 'remaining-challenges-exist': remainingChallengeSessions > 0 }"
                        class="remaining-challenges"
                    >
                        <span class="remaining-challenges-text">
                            {{
                                $t(remainingChallengeSessions > 0 ? 'arenaRoute.remainingChallenges' : 'arenaRoute.timeUntilNextPlay')
                            }}:
                        </span>
                        <span class="remaining-challenge-value">
                            {{
                                remainingChallengeSessions > 0 ? remainingChallengeSessions : timeUntilAvailableSessions
                            }}
                        </span>
                    </div>
                    <span class="game-create-title">{{ $t('arenaRoute.selectGame') }}</span>
                </div>
                <div class="challenges-container">
                    <b-col class="carousel-outer-container">
                        <slider
                            ref="arenaChallengeCarousel"
                            :options="carouselOptions"
                            :slides-to-scroll="challengesToScroll"
                            class="challenge-carousel"
                            @slide="slideChanged"
                        >
                            <slideritem
                                v-for="challenge in availableChallenges"
                                :key="challenge.id"
                                :style="{'--slide-width-percentage': slideWidth + '%'}"
                                class="challenge-slide"
                            >
                                <arena-challenge
                                    :challenge="challenge"
                                />
                            </slideritem>
                        </slider>
                        <div
                            v-if="showPrevArrow(availableChallenges)"
                            class="arrow left"
                            @click="prevSlide"
                        >
                            <font-awesome-icon :icon="['fas','chevron-left']" />
                        </div>

                        <div
                            v-if="showNextArrow(availableChallenges)"
                            class="arrow right"
                            @click="nextSlide"
                        >
                            <font-awesome-icon :icon="['fas','chevron-right']" />
                        </div>
                    </b-col>
                </div>
            </div>
            <challenge-invitations :pending-challenge-sessions="pendingChallengeSessions" />
            <challenge-history :completed-challenge-sessions="completedChallengeSessions" />
        </div>
        <div v-else class="loader-container">
            <loader
                loader-color="var(--main-content-text)"
            />
        </div>
    </div>
</template>

<script>
    import ArenaChallenge from '@/components/arena/ArenaChallenge'
    import EditIconSvg from '@/components/svg/EditIconSVG'
    import { slider, slideritem } from 'vue-concise-slider'
    import ChallengeHistory from '@/components/arena/ChallengeHistory.vue'
    import ChallengeInvitations from '@/components/arena/ChallengeInvitations.vue'
    import taskUtil from '@/utils/task-util'
    import dateConversion from '@/utils/date-conversion'
    import Loader from '@/components/Loader'
    import ReadMore from '@/components/ReadMore'

    export default {
        components: {
            ChallengeInvitations,
            ChallengeHistory,
            ArenaChallenge,
            slider,
            slideritem,
            EditIconSvg,
            Loader,
            ReadMore
        },
        data() {
            return {
                carouselIndex: 0,
                carouselOptions: {
                    pagination: false
                },
                dataInterval: null,
                innerWidth: window.innerWidth,
                timeUntilAvailableSessions: '',
                timeUntilAvailableSessionInterval: null,
                isLoading: false
            }
        },
        computed: {
            theming() {
                return this.$store.getters.getTheming
            },
            availableChallenges() {
                return this.$store.getters.availableChallenges
            },
            completedChallengeSessions() {
                return this.$store.getters.getCompletedChallengeSessions
            },
            pendingChallengeSessions() {
                return this.$store.getters.getPendingChallengeSessions
            },
            remainingChallengeSessions() {
                return this.$store.getters.getRemainingChallengeSessions
            },
            challengesToScroll() {
                return this.innerWidth > 992 ? 4 : 1
            },
            slideWidth() {
                return 100 / this.challengesToScroll
            },
            task() {
                const missionId = parseInt(this.$route.params.missionId)
                const taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            nextAvailableSessionDate() {
                return this.$store.getters.getNextAvailableSessionDate
            },
            currentPlayer() {
                return this.$store.getters.getCurrentUser
            },
            routeSubtitle() {
                return this.$t('arenaRoute.bottomTitleText')
            }
        },
        watch: {
            nextAvailableSessionDate: {
                handler(newVal) {
                    clearInterval(this.timeUntilAvailableSessionInterval)
                    this.timeUntilAvailableSessionInterval = this.createTimeUntilAvailableSessionInterval(newVal)
                }
            }

        },
        async created() {
            this.refreshRoute()
            let missionId = parseInt(this.$route?.params?.missionId)
            let taskId = parseInt(this.$route?.params?.taskId)

            if (this.task?.id) {
                const isInvalid = await this.$store.dispatch('startTask', { missionId, taskId })
                if (isInvalid) {
                    this.$router.push({ path: '/missions/' + missionId, replace: true })
                } else {
                    taskUtil.updateTask(this.mission, this.task, this.task.points, true)
                }
            }
            this.dataInterval = setInterval(() => {
                this.$store.dispatch('fetchAvailableChallenges')
                this.$store.dispatch('fetchHistoryChallengeSessions')
                this.$store.dispatch('fetchPendingChallengeSessions')
                this.$store.dispatch('fetchRemainingChallengeSessions')
                this.$store.dispatch('refreshCurrentUser')
            }, 10000)
            window.addEventListener('resize', this.onResize)
            this.timeUntilAvailableSessionInterval = this.createTimeUntilAvailableSessionInterval(this.nextAvailableSessionDate)
        },
        mounted() {
            this.refreshRoute()
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize)
            clearInterval(this.dataInterval)
            this.dataInterval = null
            clearInterval(this.timeUntilAvailableSessionInterval)
            this.timeUntilAvailableSessionInterval = null
        },
        methods: {
            refreshRoute() {
                this.isLoading = true
                Promise.all([
                    this.$store.dispatch('fetchAvailableChallenges'),
                    this.$store.dispatch('fetchHistoryChallengeSessions'),
                    this.$store.dispatch('fetchPendingChallengeSessions'),
                    this.$store.dispatch('fetchRemainingChallengeSessions')
                ]).finally(() => {
                    this.isLoading = false
                })
            },
            copyToClipboard() {
                navigator.clipboard.writeText(this.currentPlayer.challengerId)
                this.displayToast(null, 'Challenger Id copied')
            },
            goToArenaSettings() {
                this.$router.push({ name: 'settings', query: { tab: 'arena' } })
            },
            showPrevArrow(items) {
                return this.carouselIndex > 0 && this.hasBelowChallengesToScroll(items)
            },
            showNextArrow(items) {
                return this.carouselIndex < items.length - 1 && this.hasBelowChallengesToScroll(items)
            },
            hasBelowChallengesToScroll(items) {
                return this.challengesToScroll < items.length
            },
            prevSlide() {
                this.$refs.arenaChallengeCarousel.$emit('slidePre')
            },
            slideChanged(data) {
                this.carouselIndex = data.currentPage
            },
            nextSlide() {
                this.$refs.arenaChallengeCarousel.$emit('slideNext')
            },
            onResize() {
                this.innerWidth = window.innerWidth
            },
            createTimeUntilAvailableSessionInterval(nextAvailableSessionDate) {
                return setInterval(() => {
                    this.timeUntilAvailableSessions = dateConversion.getRemainingTime(
                        nextAvailableSessionDate,
                        (new Date()).toISOString(),
                        true,
                        true,
                        false,
                        ' '
                    )
                }, 1000)
            }
        }
    }
</script>

<style>
.swiper-container-horizontal > * > .slider-wrapper {
    gap: 0.75rem;
}
</style>

<style lang="scss" scoped>
.loader-container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.arena-container {
    padding: 1rem 1rem 6rem;
    background-image: var(--arena-bg);
    background-size: cover;
    flex-grow: 1;
}

.arena-title,
.arena-subtitle {
    text-align: left;
    color: var(--main-content-text);
}

.arena-title {
    font-weight: bold;
    font-size: 150%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arena-id {
    max-width: 360px;
    font-size: 1rem;
    border-radius: 0.35rem;
    color: var(--main-content-text);
    background: rgb(0 159 227 / 30%);
    box-shadow: 0 2.8px 20px 0 rgb(0 0 0 / 6%) inset;
    padding: 0 1rem;
    margin: 1rem 0;
    position: relative;
    height: 41px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.edit-arena-id {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background: #fff;
    border-radius: 0.35rem;
    color: #404e9e;
}

.arena-subtitle {
    font-size: 100%;
    font-weight: 500;
}

.arena-header-icon {
    width: 55px;
    height: auto;
}

.underlay {
    background: rgb(255 255 255 / 15%);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
}

.game-create-header {
    color: var(--main-content-text);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.game-create-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.remaining-challenges {
    border: 2px solid var(--main-content-text);
    border-radius: 10px;
    min-height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0 1rem;
}

.remaining-challenges-exist {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.remaining-challenges-text {
    font-weight: 500;
    font-size: 1rem;
}

.challenges-container {
    margin-top: 0.5rem;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: var(--main-content-text);
    padding: 1rem;
    cursor: pointer;
}

.arrow .svg-inline--fa {
    font-size: 2rem;
}

.arrow.left {
    left: 0;
}

.arrow.right {
    right: 0;
}

@media screen and (min-width: 768px) {
    .remaining-challenges {
        font-size: 1.35rem;
    }

    .remaining-challenges-text {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 992px) {
    .arena-container {
        padding: 1rem 1rem 2rem;
    }

    .arrow.left {
        left: -2.5%;
    }

    .arrow.right {
        right: -2.5%;
    }

    .challenge-slide {
        width: var(--slide-width-percentage);
        max-width: 280px;
    }

    .game-create-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .remaining-challenges {
            order: 2;
        }

        .remaining-challenges:not(.remaining-challenges-exist) {
            min-width: 330px;
        }

        .remaining-challenges:not(.remaining-challenges-exist) .remaining-challenges-value {
            text-align: left;
        }

        .game-create-title {
            font-size: 2.25rem;
        }
    }

    .challenges-container {
        margin-top: 1rem;
    }

    .arena-id {
        font-size: unset;
    }
}
</style>
