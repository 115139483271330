<template>
    <div>
        <span v-if="!showMore">{{ truncatedText }}</span>
        <span v-else>{{ text }}</span>
        <span v-if="isTextLongerThanMaxLength" class="read-label" @click="toggleReadMore"> {{ showMore ? $t('helpers.readLess') : $t('helpers.readMore') }}</span>
    </div>
</template>

<script>
    export default {
        props: {
            text: {
                type: String,
                required: true
            },
            maxLength: {
                type: Number,
                required: false,
                default: 100
            }
        },
        data() {
            return {
                showMore: false
            }
        },
        computed: {
            isTextLongerThanMaxLength() {
                return this.text.length > this.maxLength
            },
            truncatedText() {
                if (!this.isTextLongerThanMaxLength) {
                    return this.text
                }
                return this.text.substring(0, this.maxLength) + '...'
            }
        },
        methods: {
            toggleReadMore() {
                this.showMore = !this.showMore
            }
        }
    }
</script>

<style scoped>
.read-label {
    font-weight: bold;
    cursor: pointer;
    font-size: 0.95rem;
}
</style>
